body {
  font-family: 'Inter', sans-serif;
  overflow: hidden;
  background-color: #F6F1F5;
}
.App {
  text-align: center;
  height: 100vh;
  background-color: #F6F1F5;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.guidedpencontainer {
  margin: 0 auto;
  text-align: center;
  top: 0px;
  position: absolute; /* Messes up the showing in the middle, but needed so chapters show nicely */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ProseMirror {
  background-color: #fff;
  color: #111;
  min-height: 354px;
  max-height: 88vh;
  border-radius: 10px;
  border: 1px solid rgb(204, 204, 204);
  font-size: 15px;
  padding: 20px;
  padding-left: 20px;
  width: 100%;
  margin: 0 auto;
  max-width: 718px;
  word-wrap: break-word;
  white-space: pre-wrap;
  white-space: break-spaces;
  -webkit-font-variant-ligatures: none;
  font-variant-ligatures: none;
  font-feature-settings: "liga" 0;
  overflow-y: auto;
  text-align: left;
  z-index: 1;
}

h1, h2 {
  line-height: 1.3em;
}


@import '~@fortawesome/fontawesome-free/css/all.css';

.toolbar {
  margin: 0 auto;
  text-align: center;
  position: unset;
  display: block;
  padding-bottom: 1px;
  text-align: right;
  position: fixed;
  width: 100%;
  text-align: right;
  width: 1400px;
  margin: 0 auto;
}

.toolbar button {
  background-color: #FCF3EE;
  color: #333;
  padding: 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 11px;
  border: 1px solid #888;
  margin: 3px;
  opacity: 0.5;
  width: 30px;
  height: 30px;
  vertical-align: text-top;
  margin-top: 10px;
}

.toolbar button:hover {
  opacity: 1;
}
.highlighted-text {
  visibility: hidden;
  height: 1px;
  width: 1px;
  overflow: auto;
}
#highlightexists {
  color: #333;
  font-size: 14px;
  font-style: italic;
  position: absolute;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  left: 0px;
  margin-top: 6px;
  display: none;
}
.guidingarena {
  /* max-width: 1582px;
  min-height: 500px;
  max-height: 100vh;
  margin: 0 auto; */
  display: inline-block;
  /* width: 100%; */
}
#guidingforce {
  width: 656px;
  max-width: calc(100% - 54px);
  background-color: rgba(176, 196, 222, 0.1);
  border: 2px solid #777;
  height: 70px;
  color: #000;
  margin: 0 auto;
  border-radius: 10px;
  text-align: left;
  padding: 20px !important;
  font-size: 15px;
  padding-right: 59px !important;
  padding-bottom: 32px !important;
  overflow: auto;
  min-height: 340px;
  line-height: 1.6em;
  padding-top: 16px;
  padding-left: 15px;
  float: left;
  position: relative;
  z-index: 1;
}


.color-red {
  color: red;
}
button.BubbleButton {
  background-color: mediumaquamarine;
}
#tippy-1 {
  height: 29px;
}
.Chapters {
  position: absolute;
  color: #000;
  width: 239px;
  display: inline-block;
  height: 798px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 100px;
}

.Chapters ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 112px;
  float: left;
  margin-left: 10px;
}
.Chapters ul:hover {
  width: 190px;
}

.Chapters li {
  font-size: 10px;
  line-height: 32px;
  opacity: 0.4;
  margin-top: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  padding-left: 17px;
  margin-left: -17px;
}
.Chapters li:hover { 
  background: white;
  opacity: 1;
  cursor: pointer;
}
li.chapters.chaptersactive {
  background-color: rgba(255, 255, 255, 0.4);
  /* border-bottom: 1px solid #cecece; */
  opacity: 1;
}
button.guidedsubmit {
  width: 100%;
  max-width: 37px;
  height: 33px;
  line-height: 33px;
  float: right;
  position: relative;
  margin-top: -44px;
  border-radius: 5px;
  border: none;
  right: 5px;
  display: none;
}
.guidedsubmit:hover {
  cursor: pointer;
}
.outerguided {
  margin: 0 auto;
  display: inline-block;
  /* width: 730px; */
  float: left;
}
.tiptap.ProseMirror {
  float: left;
}
.loginbutton {
  height: 23px;
  margin-right: 7px;
  font-size: 10px;
  background-color: lightsteelblue;
  color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  float: right;
  margin-top: 2px;
}
.loginbutton:hover {
  cursor: pointer;
}
.login {
  right: 0px;
  position: fixed !important;
  top: 0px;
  font-size: 12px;
  color: #333;
  z-index: 999;
}
.loginbutton:hover {
  background-color: #02578a;
}
.userimage {
  margin-right: 14px;
  margin-top: 10px;
  cursor: pointer;
  display: block;
  height: 25px;
  width: 25px;
  background: transparent;
  right: 0px;
  color: #fff;
  border: none;
  margin-bottom: -4px;
}
.userimage:hover {
  cursor: pointer;
}
.loginfield {
  height: 17px;
  margin-top: 3px;
  margin-right: 5px;
  width: 100px;
  background-color: rgba(255, 255, 255, 0.76);
  border: 1px solid #ccc;
  border-radius: 2px;
  padding-left: 5px;
  font-size: 10px;
}
#newchapter {
  height: 25px;
}
#newchapter::placeholder {
  font-style: italic;
  font-size: 12px;
  color: #666;
  text-decoration: underline;
}
.DeleteButton {
  background: transparent;
  border: none;
  margin-top: 9px;
  font-size: 12px;
  opacity: 0.3;
  display: inline-block;
  margin-left: 10px;
}
.Chapters li:hover{
display: block;
}

.DeleteButton:hover {
  color: red; /* Change delete button color on hover */
  opacity: 1;
}

ul li:hover button {
  cursor: pointer;
}
input#newchapter {
  background: transparent;
  border: none;
  padding: 5px;
  margin-left: -5px;
}
/* Style for the input itself on hover and focus */
input#newchapter:hover, input#newchapter:focus {
  background: white;
  border: none;
  opacity: 1;
  border: none;
  outline: none;
}

/* Highlight the entire li when the input inside it is focused */
li:focus-within {
  background: white;
  opacity: 1;
}

.tiptap.ProseMirror {
  line-height: 1.6em;
}
.replaceLeft {
  background-color: lightsteelblue;
  border: none;
  height: 35px;
  top: 50%;
  margin-bottom: 10px;
  color: white;
  width: 60px;
  display: block;
}
.replaceLeft:hover {
  cursor: pointer;
  background-color: #ccc;
}
.replaceLeft.dark {
  background-color: #666;
}
.replaceLeft.hacker {
  background-color: #333;
}
#unset {
  display: none;
  color: #cc0000;
}
#unset:hover {
  background-color: red;
  color: #fff;
  opacity: 0.6;
}
#AIButtons {
  display: inline;
  float: left;
  margin-left: 114px;
}
button#undo {
  display: none;
  border: none;
  width: 35px;
  height: 35px;
  left: 52%;
  margin-top: -45px;
  margin-bottom: 10px;
}
button#undo:hover {
  cursor: pointer;
}

.notes {
  border: 1px solid #dfdfdf;
  background-color: #ccc;
  width: 55px !important;
  height: 23px;
  white-space: nowrap;
  font-size: 8px;
  border-radius: 4px;
  opacity: 0.4;
  padding-left: 8px;
  padding-right: 8px;
  position: relative;
  display: block;
  min-width: 63px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 5px;
}
.notes:hover {
  cursor: pointer;
  opacity: 0.9;
  background-color: #fff;
  color: #000;
}
.selected-note {
  background-color: rgba(176, 196, 222, 0.1);
  opacity: 1;
}
.ainotes {
  width: 75px;
  position: relative;
  max-height: 263px;
  white-space: nowrap;
  overflow-x: hidden; /* Ensure the div is scrollable */
  overflow-y: auto;
}

.ainotes::-webkit-scrollbar {
  width: 4px;
}

.ainotes::-webkit-scrollbar-thumb {
  background-color: #dfdfdf; /* Set the color of the scrollbar thumb */
  border-radius: 4px; /* Set the border radius of the scrollbar thumb */
}

.ainotes::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* Set the color of the scrollbar track */
  border-radius: 6px; /* Set the border radius of the scrollbar track */
}

.ainotes::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Set the color of the scrollbar thumb on hover */
}

#guidingforce::-webkit-scrollbar {
  width: 4px; /* Set the width of the scrollbar */
}

#guidingforce::-webkit-scrollbar-thumb {
  background-color: #cfcfcf; /* Set the color of the scrollbar thumb */
  border-radius: 4px; /* Set the border radius of the scrollbar thumb */
}

#guidingforce::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* Set the color of the scrollbar track */
  border-radius: 6px; /* Set the border radius of the scrollbar track */
  margin-top: 10px;
  margin-bottom: 10px;
}

#guidingforce::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Set the color of the scrollbar thumb on hover */
}

.ProseMirror::-webkit-scrollbar {
  width: 4px; /* Set the width of the scrollbar */
}

.ProseMirror::-webkit-scrollbar-thumb {
  background-color: #cfcfcf; /* Set the color of the scrollbar thumb */
  border-radius: 4px; /* Set the border radius of the scrollbar thumb */
}

.ProseMirror::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* Set the color of the scrollbar track */
  border-radius: 6px; /* Set the border radius of the scrollbar track */
  margin-top: 10px;
  margin-bottom: 10px;
}

.ProseMirror::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Set the color of the scrollbar thumb on hover */
}
.Chapters {
  direction: rtl; /* Move the scrollbar to the left */
  /* your existing styles */
}

.Chapters::-webkit-scrollbar {
  width: 1px;
  opacity: 0.01;
}

.Chapters::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.Chapters::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.06);
  border-radius: 6px;
}

.ainotes::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* New class for the inner content to reset text direction */
.Chapters-content {
  direction: ltr; /* Reset text direction for the content */
}

.ainotes::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Set the color of the scrollbar thumb on hover */
}

.newNoteBtn {
  border: 1px solid #dfdfdf;
  background-color: transparent;
  width: 49px;
  height: 23px;
  white-space: nowrap;
  font-size: 8px;
  border-radius: 4px;
  opacity: 0.4;
  font-size: 16px;
}
.newNoteBtn:hover {
  opacity: 1;
  cursor: pointer;
  background-color: #fff;
}
.savenotebtn {
  width: 18px;
  height: 18px;
  line-height: 18px;
  border-radius: 5px;
  border: none;
  font-size: 14px;
  color: #ccc;
  display: inline-block;
  margin-right: 5px;
  padding: 0px;
}
.savenotebtn:hover {
  color: #000;
  cursor: pointer;
}
#h1 {
  color: lightsteelblue;
  font-size: 52px;
  position: absolute;
  margin: 0 auto;
  width: 100%;
  top: 178px;
  left: 0px;
  display: none;
}
img.icon {
  width: 50px;
}
.iconlogo {
  width: 20px;
}
#h1logo {
  font-size: 24px;
  position: absolute;
  color: lightsteelblue;
  float: left;
}
a {
  text-decoration: none;
}
div#sidebar {
  float: left;
  width: 65px;
  position: relative;
  margin-left: -82px;
  margin-top: 25px;
}
#sidebarmenu {
  display: block;
  font-size: 16px;
  color: #939393;
}
.chapterhighlight {
  background: #f7f7f7;
  border: 1px solid #ddd;
  border-radius: 5px;
  color: #333;
  display: block;
  font-size: 12px;
  max-width: 70px;
  opacity: .6;
  padding: 5px;
  width: 100%;
  margin: 0 auto;
  margin-top: 5px;
}
.chapterhighlight:hover {
  cursor: pointer;
  background-color: lightsteelblue;
  opacity: 1;
  color: white;
}
.chapterhighlight.dark, .chapterhighlight.hacker {
  background: #333;
  color: #fff;
}
.aimenu {
  width: 77px;
  text-align: center;
  margin: 0 auto;
  font-size: 10px;
  display: none;
}
.aimenu.active, .ainotes.active {
  display: block;
}
.aimenubutton {
  width: 100%;
  height: 52px;
  font-size: 12px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border: none;
  border-bottom: 1px solid #ddd;
  opacity: 0.3;
}
.aimenubutton:hover {
  cursor: pointer;
  border-color: skyblue;
}
.aimenubuttonimage {
  width: 15px;
  height: 15px;
}
.aimenubutton.active {
  opacity: 1;
  background-color: rgba(176, 196, 222, 0.3);
}
.column {
  display: block;
  background-color: #F6F1F5;
}
.column1 {
  width: 53px;
  min-height: 333px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
}
.column2 {
  flex-grow: 1;
  overflow: hidden;
  background-color: rgba(176, 196, 222, 0.1);
  border: 1px solid rgb(204, 204, 204);
  background-color: rgba(176, 196, 222, 0.3);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.middlesection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 69px;
  margin: 0 auto;
  float: left;
  padding: 10px;
  margin-top: 90px;
}
.buttonlist.dark {
  background-color: #333;
}
.buttonlist.hacker {
  background-color: #222;
}
.savenotebutton2 {
  width: 18px;
  height: 18px;
  line-height: 18px;
  border-radius: 5px;
  border: none;
  font-size: 14px;
  color: #ccc;
  display: inline-block;
  padding: 0px;
}
.savenotebutton2:hover {
  color: #000;
  cursor: pointer;
}
.notebuttons {
  display: block;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
}
.outsidechapters {
  display: inline-block;
  width: 80px;
  height: 200px;
  position: absolute;
  margin-left: -31px;
}
.savenotebutton2, .savenotebtn, #guidedsubmit {
  z-index: 6;
}
div#guidingarena.layoutmode {
  margin-top: 500px;
  transition: margin-top 0.2s ease; /* Adjust the duration and easing as needed */
  pointer-events: none;
}
.sortable-list {
  list-style-type: none;
  padding: 0;
}

.sortable-item {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  margin: 0 auto;
  display: inline-block;
  margin: 5px;
  padding: 10px;
  cursor: grab;
  width: 50px;
  font-size: 13px;
  color: #000;
}

.sortable-item:hover {
  background-color: #e0e0e0;
}

.stuck {
  background-color: #ddd;
  padding: 10px;
  margin: 5px;
  cursor: default;
}

.sortable-inner-list {
  list-style-type: none;
  padding: 0;
}
li.sortable-item {
  background: white;
  width: 80px;
  font-size: 12px;
  vertical-align: middle;
  background-color: #000;
  color: #fff;
  border-radius: 3px;
  font-size: 11px;
}
.sortable-item:hover {
  background-color: orange;
  color: #000;
}
.beatscontainer {
  position: absolute;
  display: block;
  width: calc(50% - 40px); /* Match guidingforce width */
  margin-left: calc(25% - 40px); /* Center in the same space as guidingforce */
  margin-top: 45px;
  background-color: white;
  padding: 20px;
  border: 2px solid #cbcbcb;
  border-radius: 10px;
  min-height: 450px;
  z-index: 6;
  animation: appear 1s ease-out forwards;
  font-family: "Space Grotesk", sans-serif;
}

/* Add better responsive handling */
@media (max-width: 1200px) {
  .beatscontainer {
    width: calc(100% - 40px);
    margin-left: 20px;
    margin-right: 20px;
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.beatbox {
  background-color: #fff;
  display: inline-block;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 20px;
  font-size: 10px;
  max-width: 170px;
  vertical-align: text-top;
  margin: 20px;
  font-family: "Space Grotesk", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.beatbutton {
  display: block;
  background-repeat: repeat;
  background-color: #fff;
  background-size: cover;
  width: 100%;
  max-width: 325px;
  height: 250px;
  outline: 1px solid #9c9c9c; 
  border-radius: 40px;
  margin: 20px;
  margin-top: 100px;
}
.beatbutton:hover {
  cursor: pointer;
  outline: 6px solid gold;
}
.beatbutton.SaveTheCat {
  background-image: url(https://guidedpen.com/img/beatcat.fw.png);
}
.beatbutton.LoopedNarrative {
  background-image: url(https://guidedpen.com/img/beat-circular.fw.png);
}
.beatbutton.HerosJourney {
  background-image: url(https://guidedpen.com/img/beat-hero.fw.png);
}
.beatbutton.MediasRes {
  background-image: url(https://guidedpen.com/img/beat-mediasres.fw.png);
}
.beatbutton.TransformationBlueprint {
  background-image: url(https://guidedpen.com/img/beat-transformationblueprint.fw.png);
}
.beatbutton.Freeform {
  background-image: url(https://guidedpen.com/img/beat-freeform.fw.png);
}
.unmatchedheadline {
  font-size: 20px;;
}
.hidden {
  display: none !important;
}
.nonhidden {
  display: inline-block;
}

.guidingarenahidden {
  bottom: -200px; /* Starting position */
  display: block;
  text-align: center;
  margin-top: 55px;
  left: 50%;
  opacity: 0.4; /* Final opacity */
}
#beatjumpback {
  position: absolute;
  top: 0px;
  color: #ccc;
  font-size: 20px;
  background-color: #fff;
  padding: 10px;
  font-family: "Kalam", cursive;
  font-weight: 700;
  font-style: normal;
}
#beatheader {
  display: table;
  background-color: gold;
  padding: 14px;
  margin: 0 auto;
  font-size: 28px;
  font-weight: bold;
  padding-left: 28px;
  padding-right: 28px;
  margin-bottom: 32px;
  margin-top: -4px;
}
#beatjumpback:hover {
  cursor: pointer;
}
.chevrontoggle {
  border: none;
  font-size: 34px;
  color: #fff;
  position: relative;
  top: -10px;
  background: black;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  cursor: pointer;
}
.chevrontoggle:hover {
  background-color: #333;
  cursor: pointer;
}
.beatsheetbutton {
  background-color: gold !important;
  opacity: 1 !important;
  color: #000;
}
.beatsheetbutton:hover {
  background-color: orange !important;
}
.beatdesc {
  font-size: 11px;
  font-style: italic;
}
.App {
  overflow-y: auto;
}
.App::-webkit-scrollbar {
  width: 4px; /* Set the width of the scrollbar */
}

.App::-webkit-scrollbar-thumb {
  background-color: #fff; /* Set the color of the scrollbar thumb */
  border-radius: 4px; /* Set the border radius of the scrollbar thumb */
}

.App::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* Set the color of the scrollbar track */
  border-radius: 6px; /* Set the border radius of the scrollbar track */
  margin-top: 10px;
  margin-bottom: 10px;
}

.App::-webkit-scrollbar-thumb:hover {
  background-color: #fff; /* Set the color of the scrollbar thumb on hover */
}
.mostoutside {
  display: inline-block;
  width: 100%;
}
.beatbox.Unmatched {
  display: block;
  width: 100%;
  max-width: 90%;
  margin: 0 auto;
  background-color: transparent;
  border: none;
}
.fullwidth {
  width: 100%;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.modal-overlay2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.modal-content2 {
  background: mintcream;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  min-height: 200px;
  max-width: 388px;
}

.modal-close-button2 {
  position: absolute;
  top: 11px;
  right: 10px;
  border: none;
  background: none;
  font-size: large;
  cursor: pointer;
  display: block;
  text-align: right;
  color: #ccc;
  display: none;
}


.modal-content {
  background: mintcream;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  min-height: 200px;
  max-width: 388px;
  width: 82%;
}

.modal-close-button {
  position: absolute;
  top: 11px;
  right: 10px;
  border: none;
  background: none;
  font-size: large;
  cursor: pointer;
  display: block;
  text-align: right;
  color: #ccc;
  display: none;
}
.cardInputs {
  padding: 10px;
  padding-top: 12px;
  padding-bottom: 8px;
  border: 1px solid rgb(204, 204, 204);
  display: revert-layer;
  margin-bottom: 5px;
  width: 100%;
  max-width: 364px;
  box-sizing: border-box;
}
.cardInputs::placeholder {
  color: #A2A2A2;
  font-size: 15px;
}
.paymentheader {
  color: #666;
  font-size: 28px;
}
.paymentButton {
  width: 100%;
  height: 46px;
  margin-top: 15px;
  background: darkseagreen;
  cursor: pointer;
  color: #444;
  font-size: 16px;
  font-weight: bold;
  padding-top: 4px;
}
.paymentbody {
  color: #555;
  font-size: 18px;
  line-height: 24px;
  display: flex;
}
.paymentprice {
  font-size: 16px;
  text-align: right;
  color: green;
  font-weight: bold;
  margin-top: 14px;
}
.successMessage {
  margin-top: 68px;
  font-size: 17px;
  color: #222;
}
#UsageCount {
  color: #888;
  font-size: 10px;
  display: inline-block;
  float: right;
  position: relative;
  margin-top: -34px;
  border-radius: 5px;
  border: none;
  right: 5px;
  margin-right: 10px;
  height: 25px;
  width: 35px;
  z-index: 2;
}
.usagered {
  background-color: red !important;
  color: #fff !important;
}
#UsageCount:hover {
  cursor: pointer;
}
.WorkTitleInput {
  float: left;
  position: relative;
  height: 28px;
  min-width: 144px;
  border: none;
  background-color: transparent;
  font-size: 12px;
  color: lightsteelblue;
  margin-bottom: -30px;
  margin-top: 16px;
  max-width: 120px;
  padding-bottom: 0px;
  display: block !important;
  padding-left: 8px;
  margin-left: -8px;
  line-height: 32px;
  cursor: default !important;
}
.WorkTitleInput:hover {
  background-color: #fff;;
}
button.BubbleButton {
  background-color: steelblue !important;
  color: white;
  height: 25px;
  margin-right: 5px;
  border-radius: 5px;
  border: 1px solid #666;
  font-size: 12px;
  top: -28px;
  position: relative;
}
.BubbleButton:hover {
  cursor: pointer;
}
/* DarkMode */
.App.dark {
  background-color: #333;
}
.App.hacker {
  background-color: #222;
}
.guidingforce.normal {
  background-color: #fff !important;
}
.guidingforce.dark {
  background-color: #fff !important;
}
.guidingforce.hacker {
  background-color: #333 !important;
  color: #fff !important;
}
.chapters.dark, .chapters.hacker {
  color: #eee;
}
.chapters.dark:hover, .chapters.hacker:hover {
  color: #333;
}
.chaptersdarkmode.chaptersactive:hover {
  color: #fff;
}
.hackerMode {
  background-color: #444;
  color: #fff;
}

.tiptap.ProseMirror {
  background-color: #fff !important;
  color: #333 !important;
}
.tiptap.ProseMirror.dark {
  background-color: #fff !important;
  color: #333 !important;
  /* Other dark mode styles */
}
.tiptap.ProseMirror.hacker {
  background-color: #333 !important;
  color: #EEE !important;
  /* Other hacker mode styles */
}
.bookshelfheader {
  color: #333;
}
.modalcontentprofile {
  min-height: 437px;
  width: 80%;
  max-width: 825px;
}
.button-item {
  display: flex;
  align-items: center;
  padding-top: 18px;
}

.button-item div {
  flex-grow: 1;
}

.button-item button {
  margin-left: 10px;
}
.buttonorder {
  background-color: yellow;
  position: absolute;
  color: #000;
  top: 58px;
  width: 500px;
}
.modalbutton {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  left: 0px;
  top: 0px;
  color: #000;
}
.modal-content.modal-contentbutton {
  margin: 0 auto;
  top: 200px;
  max-width: 500px;
}
.button-item {
  text-align: left;
  font-size: 12px;
}
.buttonarrows {
  color: darkgreen;
  background-color: transparent;
  border: none;
}
.buttonarrows:hover {
  cursor: pointer;
}
.buttonlist {
  margin-bottom: 20px;
  max-height: 742px;
  min-height: 300px;
  overflow-y: auto;
  padding: 4px;
  z-index: 0;
  background: #f6f1f5;
  width: 200px;
  text-align: center;
  align-content: unset;
}
.buttonlist::-webkit-scrollbar {
  width: 2px; /* Set the width of the scrollbar */
}
.buttonlist::-webkit-scrollbar-thumb {
  background-color: #ddd; /* Set the color of the scrollbar thumb */
  border-radius: 4px; /* Set the border radius of the scrollbar thumb */
}
.buttonlist::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* Set the color of the scrollbar track */
  border-radius: 6px; /* Set the border radius of the scrollbar track */
  margin-top: 10px;
  margin-bottom: 10px;
}
.buttonlist::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Set the color of the scrollbar thumb on hover */
}
#autocomplete-box {
  position: absolute;
  border: 1px solid #ccc;
  background-color: white;
  z-index: 1000; /* Ensure it's above other content */
  color: #000;
  text-align: left;
  font-size: 12px;
}
.cover {
  display: inline-block;
  width: 171px;
  height: 245px;
  background-image: linear-gradient(to bottom right, #222 50%, #333 50%);
  margin: 0 auto;
  outline: 2px solid #ccc;
  margin: 10px; 
  opacity: 0.9;
}
.cover:hover {
  cursor: pointer;
  outline: 6px solid lightsteelblue;
  opacity: 1;
}
.worksTitleHeader {
  color: #fff;
  font-size: 12px;
  display: flex;
  align-items: center; /* Vertical centering */
  justify-content: center; /* Horizontal centering */
  height: 100%;
}
.toolboxsubheadline {
  font-size: 16px;
  padding-bottom: 34px;
  width: 100%;
  margin: 0 auto;
  max-width: 470px;
}
.newbuttoncontainer {
  text-align: left;
  margin-top: 13px;
}
.newbuttontext, .newbuttoncommand {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #ccc;
  margin-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.newbuttonsave {
  display: inline;
  bottom: 0px;
  position: relative;
  right: 0px;
  top: 0px;
  height: 35px;
  background: none;
  border: none;
  color: darkgreen;
}
.newbuttonsave:hover {
  cursor: pointer;
}
button.addBook {
  display: inline-block;
  height: 210px;
  background-image: linear-gradient(to bottom right, #ccc 50%, #eee 0);
  display: inline-block;
  margin: 10px;
  opacity: .9;
  outline: 2px solid #ccc;
  width: 145px;
  color: #000;
  font-weight: bold;
  opacity: 0.45;
}
.addBook:hover {
  cursor: pointer;
  outline: 6px solid orange;
  opacity: 1;
}
.beatbox.Freeform {
  min-width: 540px;
  padding-bottom: 40px;
}

/*  */


@media (max-width: 970px) {
  .guidedpencontainer {
    width: 100%; 
  }
  .tiptap.ProseMirror {
    background-color: #fff !important;
    color: #333 !important;
    position: absolute;
    width: 100%;
    top: 0px;
    right: 0px;
    min-width: 100%;
    border-radius: 0px;
    padding: 30px;
    display: block;
    left: 0px;
    max-width: 100%;
    box-sizing: border-box;
    min-height: 100%;
    padding-bottom: 50px;
    font-size: 12px;
    line-height: 1.45em;
    border: none;
    outline: none;
    margin-top: 45px !important;
    padding-bottom: 150px;
  }
  .middlesection {
    z-index: 100 !important;
    position: absolute;
    display: none;
  }
  .outerguided {
    width: 100%;
    z-index: 5;
    position: absolute;
    left: 0;
    right: 0px;
    min-height: auto;
  }
  .guidingarena {
    width: 100%;
    left: 0px;
    right: 0px;
    position: fixed;
    top: 0px;
    bottom: 0px;
  }
  #guidingforce {
    width: 100%;
    min-height: 40vh !important;
    bottom: 0px;
    position: fixed;
    padding: 44px !important;
    box-sizing: border-box;
    min-width: 102%;
    display: none;
    font-size: 12px;
    line-height: 1.45em;
    touch-action: 'none';
    padding-bottom: 64px !important;
    border: none;
    outline: none;
  }
   .notebuttons {
    display: none;
  }
  .toolbar {
    z-index: 9;
    position: fixed;
    top: 0px;
    width: 100%;
    text-align: center;
    background-color: lightsteelblue;
    height: 43px;
    border-bottom: 1px solid #888;
  }
  .beatsheetbutton {
    width: 33px;
    height: 33px; 
  }
  .login {
    z-index: 70;
  }
  #login {
    margin-top: 52px;
    margin-left: 20px
  }
  .userimage {
    height: 25px;
    width: 25px;
    margin-top: 60px;
    margin-left: -18px;
  }
  .toolbarouter {
    position: absolute;
    top: 0px;
    left: 0px;
  }
  #AIButtons {
    display: none;
  }
  .chapterhighlight {
    display: none;
  }
  .middlesection {
    bottom: 43px;
    height: 33px;
    left: calc(50% - 25px);
    margin: 0 auto;
    padding: 0;
    position: fixed;
    width: 100%;
    position: static;
  }
  .replaceLeft {
    border: 1px solid #333;
    rotate: -90deg;
    border: 10px;
    position: absolute;
    margin-bottom: -117px;
  }
  #undo {
    display: inline-block !important;
    position: fixed;
    bottom: 13px;
    margin-left: 41px;
  }
  #replaceRight {
    margin-left: -42px;
  }
  #outsidechapters {
    z-index: 3;
    right: 0px;
    width: 220px;
    overflow-y: scroll !important;
  }

  #outsidechapters::-webkit-scrollbar {
    width: 4px;
  }
  
  #outsidechapters::-webkit-scrollbar-thumb {
    background-color: #dfdfdf; /* Set the color of the scrollbar thumb */
    border-radius: 1px; /* Set the border radius of the scrollbar thumb */
  }
  
  #outsidechapters::-webkit-scrollbar-track {
    background-color: #f0f0f0; /* Set the color of the scrollbar track */
    border-radius: 6px; /* Set the border radius of the scrollbar track */
  }
  
  #outsidechapters::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Set the color of the scrollbar thumb on hover */
  }
  

  .Chapters {
    right: 0px;
    border: none;
    outline: 0px;
    background-color: #f3f3f3;
    margin-right: 0px;
    top: 10px;
  }
  .Chapters ul {
    background-color: #f3f3f3;
    margin-top: 75px;
    padding-top: 45px;
    width: 300px;
    margin-left: 20px;
    position: fixed;
    width: 100%;
    padding-left: 30px;
    max-height: 100%;
    right: 0px;
    position: absolute;
    width: 200px;
    overflow: hidden !important;
  }
  .showsidebar {
    display: block !important; /* Or whatever display type is appropriate */
  }.outsidechapters {
    display: none;
  }
  .Chapters ul:hover {
    width: 200px;
  }
  .Chapters li {
    opacity: 0.7;
  }
  #guidingforce {
    border-radius: 0px;
  }
  .toolbar.dark {
    background-color: #666;
  }
  .tiptap.ProseMirror.dark {
    background-color: #444 !important;
    color: #fff !important;
  }
  .tiptap.ProseMirror.hacker {
    background-color: #000 !important;
    color: #cecece !important;
  }
  .toolbar.hacker {
    background-color: #333;
  }
  .guidingforce.normal {
    color: #111;
    background-color: aliceblue !important;
  }
  .guidingforce.dark {
    color: #fff !important;
    background-color: #222 !important;
  }
  .guidingforce.hacker {
    color: #ccc!important;
    background-color: #222 !important;
  }
  button.notes {
    display: none;
}
#sidebar {
  position: absolute !important;
  z-index: 200;
  top: 0px;
  height: 250px;
  width: 50px;
  display: inline-block;
  opacity: 1;
  right: 100px;
  }
  .mobileselect {
    position: absolute;
    z-index: 11111;
    right: -90px;
    background: red;
    top: 20px;
    margin-left: 25px;
    margin-top: 33px;
    display: none !important;
  }
  .chapters.dark, .chapters.hacker {
    color: #000;
    opacity: 0.85;
  }
  .beatscontainer {
    max-width: 100%;
    width: 100%;
    min-width: 100%;
    position: relative;
    left: 0px;
    right: 0px;
    top: 0px;
    border: 0px;
    overflow-y: auto;
    margin: 0px;
    padding: 0px;
    padding-top: 40px;
    padding-bottom: 120px;
    overflow: hidden;
  }
  .mostoutside {
    display: inline-block;
    width: 100%;
    margin: 0px;
  }
  
}

.hide {
  display: none;
}
.tbutton {
  height: 30px;
  width: 28px;
  padding: 4px;
  margin: 4px;
}
.tbutton:hover{
  cursor: pointer;
}
#sidebartogglebutton {
  display: none;
}
#sidebartogglebutton.FullScreenMode {
  display: inline-block;
}
/* CSS for the transition effect */
.fade-background {
  transition: background-color 1s ease; /* Adjust timing if needed */
}
.bubble-menu {
  visibility: hidden; /* Hide by default */
  opacity: 0; /* Start fully transparent */
  transition: visibility 0s linear 0.1s, opacity 0s; /* Instant opacity change with a delay on hide for visibility */
  display: none;
}

.bubble-menu.visible {
  visibility: visible; /* Make visible */
  display: block;
  opacity: 1; /* Instantly become fully opaque */
  /* Remove transition-delay here to make it instant */
  transition: visibility 0s, opacity 0s; /* Instant changes for showing */
}
.BubbleButton {
  opacity: 0.4;
}
.BubbleButton:hover {
opacity: 1;
}
.exportModalOuter {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: midnightblue;
  opacity: 0.4;
  z-index: 6; 
}
.exportModal {
  position: fixed;
  top: 20%;
  left: 35%;
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #ccc;
  z-index: 7;
  width: 100%;
  max-width: 600px;
  min-height: 310px;
  color: #000;
  border-radius: 10px;
}
.sizeselection {
  font-size: 17px;
}
.sizeselection label {
margin: 20px;
cursor: pointer;
}
.sizeselection label input {
  cursor: pointer;
  }
.exportBook {
  background-color: #C1C1FF;
  color: #fff;
  width: 100%;
  max-width: 300px;
  height: 48px;
  margin-top: 70px;
  font-size: 20px;
  outline: 2px solid;
  outline-color: slateblue;
  border: none;
}
.exportBook:hover {
  cursor: pointer;
}
.scribando {
  font-size: 14px;
  line-height: 20px;
  margin-top: 25px;
}

.editorouterFullScreenMode {
  position: fixed;
  width: 100%;
  left: 0px;
  right: 0px;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
  background: #F6F1F5;
  text-align: center;
  top: 12px;
}
.dark.editorouterFullScreenMode {
  background-color: #333;
}
.hacker.editorouterFullScreenMode {
  background-color: #222;
}
.ProseMirror.FullScreenMode {
  position: absolute;
  margin: 0 auto;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 100%;
  min-height: 100%;
  width: 100%;
  border-radius: 0px;
  outline: none;
  max-width: 800px;
  top: -12px;
  
}
.toolbar.FullScreenMode {
  position: fixed;
  left: 0px;
  margin: 0 auto;
  z-index: 2;
  top: -7px;
  right: 0px;
  max-width: 440px;
  overflow: visible;
  text-align: center;
}
#guidingforce.FullScreenMode {
  min-height: 40vh !important;
  bottom: 0px;
  position: fixed;
  padding: 44px !important;
  box-sizing: border-box;
  min-width: 102%;
  display: none;
  font-size: 12px;
  line-height: 1.45em;
  touch-action: 'none';
  padding-bottom: 64px !important;
  border-radius: 0px;
  z-index: 2;
  position: fixed;
  left: 0px;
  right: 0px;
  border: 0px;
  bottom: 0px;
  max-width: 800px;
  width: 800px !important;
  min-width: 800px !important;
  margin: 0 auto;
  height: 80px !important;
}
.ProseMirror-focused {
  z-index: 8;
}
#replaceRight.FullScreenMode {
  rotate: -90deg;
  border: 10px;
  position: absolute !important;
  z-index: 9;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: 0 auto;
  top: 100%;
  margin-top: -50px;
}
#replaceLeft.FullScreenMode {
  rotate: -90deg;
  border: 10px;
  position: absolute;
  z-index: 10;
  top: 100%;
  margin-top: -50px !important;
  right: 0px;
  margin: 0 auto;
  left: 85px;
  bottom: 0px;
  display: block;
  height: 35px !important;
}
#sidebar.FullScreenMode {
  margin-top: 0px;
  height: 50px;
  position: absolute;
  z-index: 5;
  top: 50px;
  margin-left: 0px;
}
.outsidechapters.FullScreenMode {
  z-index: 5;
  top: 50px;
  margin-left: 350px;
}
@media (max-width: 1500px) {
  .outsidechapters.FullScreenMode {
    margin-left: 270px;
  }
}
@media (max-width: 1400px) {
  .outsidechapters.FullScreenMode {
    display: none;
  }
  #sidebar {
    display: none;
  }
  .loginfield {
    width: 60px;
}
.Chapters li {
  opacity: 0.85 !important;
  color: #111 !important;
}
.Chapters {
  height: 100%;
  max-height: 100% !important;
  width: 100%;
  text-align: left;
}
#outsidechapters {
  height: 100vh;
}
}

@media (max-width: 970px) {
.toolbar.FullScreenMode {
  position: absolute;
  left: 0px;
  margin: 0 auto;
  z-index: 5;
  top: 0px;
  right: 0px;
  max-width: 100%;
}
.topbuttons, .topbutton, #mainlogin {
display: none !important;
}
#mainlogin {
  display: none;
}
#mobilelogin{
  display: block !important;
  position: relative !important;
  top: 20px;
}
}
#mobilelogin{
  display: none;
}
.topbuttons {
  text-align: right;
  position: absolute;
  display: inline-block;
  right: 10px;
  width: 100px;
}
.topbutton {
  display: inline-block;
  height: 25px;
  width: 25px;
  background: transparent;
  right: 0px;
  color: #fff;
  border: none;
  opacity: 0.5;
}
.topbutton:hover {
  cursor: pointer;
  opacity: 1;
}
.topbutton.normal {
  color: #000 !important;
}
.topbutton.hacker, .topbutton.dark {
  color: #fff !important;
}
.topbutton2 {
  display: inline-block;
  height: 25px;
  width: 25px;
  background: transparent;
  right: 0px;
  color: #000;
  border: none;
  vertical-align: bottom;
}
.mobileuserimage {
  /* display: inline-block !important; */
  margin-left: 5px !important;
  display: none;
}
.usagehidden {
  display: none !important;
}
.UsageCountFullScreenMode {
  display: none !important;
}




@media (min-width: 1400px) {
  #sidebartogglebutton {
    display: none !important;
  }
}
@media (max-width: 970px) {
  #sidebartogglebutton {
    display: inline-block !important;
  }
}
  
select.mobileselect {
  width: 70px;
  text-align: left;
  display: inline-block;
  float: left;
  position: relative;
  left: 0px;
  margin-top: 27px;
  margin-left: 160px;
  background: transparent;
  border: none;
  color: lightsteelblue;
  cursor: pointer;
  display: none !important;
}
.mobiledarkmode {
  right: 0px;
  position: absolute;
  margin-right: 10px;
}
.outsidechapters.showsidebarFullScreenMode {
  display: inline-block;
  width: 200px;
  height: 200px;
  position: absolute;
  margin-left: -56px;
  background-color: #f2f2f2 !important;
  top: 50px;
  z-index: 900;
  position: fixed;
  text-align: left;
  margin-top: -5px;
}
#savebeatgroup {
  z-index: 11;
  height: 50px;
  background: blue;
  border-radius: 0px;
  padding: 15px;
  font-size: 16px;
  display: inline-block;
}
#chevrontoggle {
  display: block;
  margin: 0 auto;
  margin-top: 14px;
  background-color: transparent;
  color: #111;
  font-size: 16px;
}
.mobileselectonmobile {
  width: 70px;
  display: inline-block !important;
  position: relative;
  background: transparent;
  border: none;
  color: lightsteelblue;
  cursor: pointer;
  background: red;
  z-index: 99;
  right: 50px;
  top: -20px;
}
select.selectOption {
  padding: 10px;
  padding-top: 12px;
  padding-bottom: 8px;
  border: 1px solid rgb(204, 204, 204);
  margin-bottom: 5px;
  height: 43px;
  background: #fff;
  border-radius: 0px;
  width: 100%;
  max-width: 364px;
  box-sizing: border-box;
}
strongly {
  background-color: gold;
  color: #111;
}
#downloadLink {
  font-size: 14px;
  text-decoration: underline;
  margin-top: 20px;
}
.collaboration-cursor__caret  {
  position: absolute;
  opacity: 1;
  visibility: visible !important;
  display: inline-block !important;
  animation: none !important;
}
@keyframes stop-cursor-blink {
  to {
    opacity: 1;
  }
}

.collaboration-cursor__caret {
  animation: stop-cursor-blink 0.5s steps(1) infinite;
}
.collaboration-cursor__label {
  background-color: gold !important;
  color: #000;
  margin-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  position: absolute;
  font-size: 12px;
  height: 15px;
  line-height: 15px;
  border-radius: 5px;
}
.SharedWithToolbar {
  display: inline-block;
  height: 38px;
  right: 0;
  text-align: left;
  width: 160px;
  margin-left: 43px;
  margin-top: 50px;
  padding-bottom: 0px;
  overflow-x: scroll;
  overflow-y: hidden;
  margin-bottom: 0px;
}
.shareduserimage {
  margin-right: 3px;
  vertical-align: top;
  position: relative;
  cursor: pointer;
}
.SharedWithImages {
  border-radius: 50%;
  height: 32px;
  margin-bottom: -20px;
  width: 32px;
}
.SharedWithImages:hover, .avatar-placeholder:hover {
  outline: 1px solid gold;
  opacity: 0.6;
}
.sharewithuserimage {
  list-style: none;
  display: inline-block;
  background-color: #333;
  color: #fff;
  border-radius: 50%;
  height: 29px;
  width: 29px;
  font-size: 18px;
  text-align: center;
  position: relative;
  vertical-align: top;
  opacity: 0.3;
  line-height: 30px;
}
.sharewithuserimage:hover {
  background-color: #ccc;
  color: #333;
  opacity: 0.9;
  cursor: pointer;
}

.sharewithuserimageinner {
  margin-top: 5px;
}

.shared-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.shared-modal-content {
  background: mintcream;
  border-radius: 5px;
  padding: 20px;
  width: 400px;
}

.shared-modal-close {
  float: right;
  font-size: 20px;
  cursor: pointer;
  color: #ccc;
}

.shared-modal-email-tag {
  background-color: powderblue;
  border-radius: 5px;
  display: inline-block;
  margin: 5px;
  padding: 5px 10px;
  font-size: 15px;
  color: #000;
}
.shareselect {
  font-size: 10px;
  background: transparent;
  border-radius: 10px;
  margin-left: 10px;
  vertical-align: middle;
  cursor: pointer;
}
.shareinput {
  background: #fff;
  padding: 10px;
  width: 100%;
  max-width: 280px;
  display: block;
  margin-top: 30px;
  border-radius: 2px;
  border: none;
  border-bottom: 1px solid #ccc;
  outline: none !important;
}
.shareinput::placeholder {
  font-style: italic;
}
.sharebutton {
  background: cadetblue;
  border: 1px solid #ddd;
  padding: 10px;
  width: 100%;
  color: #fff;
  border-radius: 3px;
  font-size: 15px;
  cursor: pointer;
}
.sharebutton:disabled {
  background: #333;
  color: #888;
  cursor: not-allowed;
}
.invitemodal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(88, 71, 149, 0.7); /* Black background with opacity */
  z-index: 999;
  color: #333;
}
.invitemodalimage {
  width: 100px;
  height: 150px;
  border: 4px solid rgba(88, 71, 149);
  position: relative;
  margin: 0 auto;
  margin-top: -75px;
}
.invitemodalimageinviter {
  width: 100px;
  height: 100px;
  border: 4px solid #584795;
  position: relative;
  margin: -75px auto 0;
  border-radius: 100% !important;
}
.inviteinput {
  width: 100%;
  max-width: 250px;
}
.avatar-placeholder{
  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: rgb(204, 204, 204);
  color: white;
  text-align: center;
  line-height: 31px;
  font-size: 16px;
  margin-right: 3px;
  vertical-align: top;
  position: relative;
}
.cover {
  width: 120px;
  height: 190px;
  background-size: cover;
  background-position: center;
  display: inline-block;
  align-items: flex-end;
  padding: 10px;
  vertical-align: top;
}
.editForm {
  position: absolute;
  background: cornsilk;
  height: 200px;
  border-radius: 10px;
  width: 100%;
  max-width: 400px;
  border: 4px solid #333;
  padding: 20px;
}
.bookeditinput {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  height: 32px;
  padding-left: 8px;
}
.booksaveinput {
  display: block;
  width: 100%;
  height: 42px;
  background-color: #000;
  color: #fff;
  cursor: pointer;
  margin-top: 10px;
}
.editButton {
  border: none;
  float: right;
  cursor: pointer;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
}
.editButton:hover {
  background-color: rgba(255, 255, 255, 1);
}
.drop-zone {
  background-color: #fff;
  color: #888;
  border: 2px solid #ddd;
  font-size: 16px;
  cursor: pointer;
}
.editclose {
  float: right;
  background: transparent;
  border: none;
  cursor: pointer;
  margin-top: -14px;
  margin-bottom: 7px;
  margin-right: -10px;
  font-size: 16px;
  color: #999;
}
.admininvitenote {
  color: #CC0000;
  text-align: center;
  margin-top: 10px;
  font-size: 15px;
  font-weight: bold;
}
.disabled-remove {
  cursor: not-allowed;
  display: none;
}
.owner {
  font-size: 10px;
  background: transparent;
  border-radius: 10px;
  margin-left: 10px;
  vertical-align: middle;
  display: inline-block;
  border-width: 1px;
  border-style: solid;
  border-color: light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
  padding-left: 3px;
  padding-right: 3px;
}
.clearaibutton {
  width: 18px;
  height: 18px;
  line-height: 18px;
  border-radius: 5px;
  border: none;
  font-size: 14px;
  color: #ccc;
  display: inline-block;
  margin-right: 5px;
  padding: 0px;
}
.clearaibutton:hover {
  color: #000;
  cursor: pointer;
}
.note-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  float: left;
  margin-left: -20px;
  position: absolute;
}
.notes {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 5px;
  width: 100%; 
  overflow: hidden;
}
.acceptinvitebutton {
  cursor: pointer;
  background: rgb(88, 71, 149);
  color: #fff;
  border: none;
  width: 100%;
  max-width: 270px;
  height: 50px;
  border-radius: 5px;
  margin-top: 4px;
}
.acceptinvitebutton:hover {
  background: rgb(128, 100, 228);
  text-decoration: underline;
}
.inviteidinput {
  visibility: hidden;
}
#inviteemail {
  position: relative;
  display: block;
  margin: 0 auto;
  margin-top: -40px;
}
.loginemail {
  float: left;
}
#WorkTitleInput {
  margin-top: 25px;
  font-size: 15px;
  width: 100%;
  max-width: 100%;
  margin-bottom: 18px;
}
#WorkTitleInput:hover {
  background-color: #3892c6;
  cursor: pointer !important;
  color: #fff !important;
}
#beatsheetbuttontext {
  font-weight: bold;
}
.magicwand {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  vertical-align: bottom;
  border-radius: 4px;
  padding: 22px;
}
#replaceLeftmagic {
  background: lightsteelblue;
  bottom: 5px;
  position: fixed;
  margin: 0 auto;
  text-align: center;
  visibility: hidden;
  rotate: none;
  margin-bottom: 20px;
  color: white;
  border: 1px solid #333;
  width: 45px;
  height: 35px;
  border-radius: 5px;
}
.beatscontainerinner {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 1200px;
  max-height: 800px;
  padding-bottom: 200px;
  margin-bottom: 200px;
}

.beatscontainerinner::-webkit-scrollbar {
  width: 4px;
}

.beatscontainerinner::-webkit-scrollbar-thumb {
  background-color: #dfdfdf; /* Set the color of the scrollbar thumb */
  border-radius: 1px; /* Set the border radius of the scrollbar thumb */
}

.beatscontainerinner::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* Set the color of the scrollbar track */
  border-radius: 6px; /* Set the border radius of the scrollbar track */
}

.beatscontainerinner::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Set the color of the scrollbar thumb on hover */
}

body, html {
  width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  height: 100% !important;
}

.mostoutside {
  display: flex !important;
  justify-content: center; /* Center the content */
  width: 1500px !important; /* Restrict the maximum width to 1500px */
  margin: 0 auto !important; /* Center the container */
}
.toolbar {
  display: block !important;
  justify-content: center; /* Center the content */
  width: 1500px !important; /* Restrict the maximum width to 1500px */
  margin: 0 auto !important; /* Center the container */
  margin-top: 0px;
  position: fixed;
}
.toolbar.FullScreenMode {
  margin-top: 0px !important;
}

@media (max-width: 1700px) {
  .mostoutside, .toolbar {
    width: 100% !important;
    width: 1200px !important;
  }
}
@media (max-width: 1500px) {
  .mostoutside, .toolbar {
    width: 100% !important;
    max-width: 1000px !important;
  }
}



/* #guidingforce, #middlesection, #editorouter {
  height: 100vh !important; Adjust height as needed
} */

/* Default styles for normal screens */
#guidingforce, #editorouter {
  flex: 1 1 !important;
  max-width: calc((1500px - 80px) / 2) !important;
  width: calc((100% - 80px) / 2) !important;
}

/* Styles for large screens */
@media (min-width: 1500px) {
  #guidingforce, #editorouter {
      max-width: 100% !important;
      width: 100% !important;
  }
}

#guidingforce {
  width: calc(100% - 80px) !important;
  margin-top: 45px;
}

#middlesection {
  width: 80px !important;
  max-width: 80px !important;
  flex: 0 0 80px !important;
}
.editorouter {
  margin-top: 45px;
}
.outerguided {
  width: calc((100% - 80px) / 2) !important;
}


@media (max-width: 970px) {
  /* Mobile-specific styles */
  #middlesection, #editorouter {
    display: block;
    width: 100% !important;
    max-width: 100% !important;
  }
  .mostoutside {
    display: inline-table;
    max-width: 1500px !important;
    width: 100% !important;
    margin: 0 auto !important;
    position: fixed;
    left: 0px;
}
  .outsidechapters.FullScreenMode {
    z-index: 899;
    top: 50px;
    margin-left: -100px;
    width: 400px;
}
.outsidechapters {
    right: 0px;
}
#editorouter {
  margin-top: 45px !important;
  z-index: 2;
}
}

@media (max-width: 970px) {
  #guidingforce {
    width: 100% !important;
    max-width: 100% !important;
    left: 0px;
    height: 50% !important;
  }
  #editorouter {
    margin-top: 0px;
    position: absolute;
    width: 100% !important;
    max-width: 100% !important;
    left: 0px;
    height: 100vh;
  }
  .tiptap.ProseMirror {
    margin-top: 0px;
    padding-top: 0px;
    margin-top: 0px !important;
  }
  #middlesection {
    display: none !important;
  }
  #h1 {
    display: none;
  }
  .SharedWithToolbar {
    position: absolute;
    z-index: 66;
    margin-top: 20px;
    width: 240px;
  }
  button#UsageCount {
    border: 0px;
    position: fixed;
    display: block;
    bottom: 10px;
    right: 20px;
  }
  .notloggedinmostoutside {
    margin-top: 30px !important;
  }
  .loggedinmostoutside {
    margin-top: 30px !important;
  }
}

.outsidechapters {
  right: -100px;
}

.showsidebarFullScreenMode {
  height: 100%;
  max-height: 100% !important;
  width: 100%;
  text-align: left;
  right: 300px;
  background: grey;
  position: absolute;
  right: 0px;
}
.loggedinmostoutside {
  margin-top: 0px !important;
}
.outsidechapters.FullScreenMode {
  margin-left: 850px;
  right: auto !important;
}
.registerbutton {
  height: 23px;
  margin-right: 7px;
  font-size: 10px;
  background-color: rgb(2 87 138);
  color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  float: right;
  margin-top: 2px;
}
.registerbutton:hover {
  cursor: pointer;
}
.SharedWithToolbar > div {
  display: flex;
}

.SharedWithToolbar ul {
  padding: 0;
  margin: 0;
  display: flex;
  list-style: none;
}

.shareduserimage {
  display: inline-block;
  margin-right: 10px;
}

.SharedWithImages {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.avatar-placeholder {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgb(204, 204, 204);
  color: white;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
}

.sharewithuserimage {
  cursor: pointer;
}
.SharedWithToolbar::-webkit-scrollbar {
  height: 4px;
  opacity: 0;
}

.SharedWithToolbar::-webkit-scrollbar-thumb {
  background-color: rgba(223, 223, 223, 0.1); /* Set the color of the scrollbar thumb */
  border-radius: 1px; /* Set the border radius of the scrollbar thumb */
}

.SharedWithToolbar::-webkit-scrollbar-track {
  background-color: rgba(167, 167, 167, 0.05); /* Set the color of the scrollbar track */
  border-radius: 6px; /* Set the border radius of the scrollbar track */
}

.SharedWithToolbar::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Set the color of the scrollbar thumb on hover */
}

.sharedbookmsg {
  color: red;
  text-align: center;
  font-size: 14px;
  margin-top: -35px;
  background: #fff;
  height: 20px;
  padding-top: 20px;
  font-weight: bold;
  }

  label {font-size: 12px;}



  .beats-header {
    border-bottom: 1px solid #eee;
    margin-bottom: 15px;
    padding-bottom: 10px;
    transition: all 0.3s ease;
  }
  
  .current-beat {
    background: #ffd700;
    padding: 8px 15px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .beat-label {
    color: #666;
    font-size: 12px;
  }
  
  .beat-name {
    font-weight: bold;
    margin: 0 10px;
  }
  
  .collapse-beats {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
  }
  
  .beats-content {
    margin-top: 15px;
    max-height: 300px;
    overflow-y: auto;
    transition: max-height 0.3s ease;
  }
  
  .beat-item {
    padding: 10px;
    border: 1px solid #eee;
    margin-bottom: 5px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .beat-item:hover {
    background: #f5f5f5;
  }
  .beat-indicator {
    background: gold;
    padding: 8px 15px;
    border-radius: 4px;
    display: inline-block;
    margin-bottom: 10px;
    transition: opacity 0.3s ease;
    opacity: 0.8;
  }
  
  .beat-label {
    color: #666;
    font-size: 12px;
    margin-right: 5px;
  }
  
  .beat-name {
    font-weight: bold;
  }
  
  /* Make it fade out when typing */
  .guidingforce:focus .beat-indicator {
    opacity: 0.3;
  }

  .beat-indicator {
    background: gold;
    padding: 8px 15px;
    border-radius: 4px;
    display: inline-block;
    margin-bottom: 10px;
    transition: opacity 0.3s ease;
    opacity: 0.8;
    pointer-events: none; /* Make it non-interactive */
    position: relative;
    z-index: 2;
  }
  
  .beat-label {
    color: #333;
    font-size: 12px;
    margin-right: 5px;
  }
  
  .beat-name {
    font-weight: bold;
    color: #000;
  }